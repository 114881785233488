import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { loadEvents } from '../../core/utils/EntityLoader';
import { getWord } from '../../core/utils/Language';
import CommonWords from '../../types/CommonWords';
import SectionHeader from '../SectionHeader';
import EventCard from './EventCard';
import NavigationMenu, { NavProps } from '../SiteNavigation/NavigationMenu';
import { FilterGroupObject, FilterProps } from '../SiteNavigation/FilterSelection';
import { Breakpoints, Colors } from '../../style/Variables';
import Functions from '../../style/Functions';
import AppearByFade from '../AnimationContainers/AppearByFade';

const Container = styled.div`
	width: 100%;
	margin-top: 180px;
	margin-bottom: 180px;
`;

const TileSection = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-auto-rows: max-content;
	grid-gap: 3em var(--gridGutter);
	margin-top: 3em;
	${Functions.breakpoint(Breakpoints.mobile)} {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export default function EventList({
	infoText,
	menuProps,
	selectedFilterProps,
}: {
	infoText: string;
	menuProps: NavProps;
	selectedFilterProps: FilterProps;
}) {
	const [events, setEvents] = useState([]);

	useEffect(() => {
		getEvents();
	}, []);

	useEffect(() => {
		getEvents();
	}, [selectedFilterProps]);

	async function getEvents() {
		var json = await loadEvents(30, selectedFilterProps.selectedDateFilter, selectedFilterProps.selectedFilters);
		if (json.data?.results) {
			setEvents(json.data?.results);
		}
	}

	return (
		<>
			<NavigationMenu
				breadcrumb={menuProps.breadcrumb}
				type={menuProps.type}
				startMenuColor={Colors.black}
				filterProps={{
					filterArray: selectedFilterProps.filterArray,
					selectedFilters: selectedFilterProps.selectedFilters,
					selectedDateFilter: selectedFilterProps.selectedDateFilter,
					changeHandler: selectedFilterProps.changeHandler,
					dateChangeHandler: selectedFilterProps.dateChangeHandler,
				}}
			/>
			<Container>
				<SectionHeader
					title={getWord(CommonWords.event)}
					subtitle={infoText}
					numberOfItems={events ? events.length : undefined}
				/>
				<TileSection>
					{events ? (
						events.map((event, i) => (
							<AppearByFade key={i}>
								<EventCard data={event}></EventCard>
							</AppearByFade>
						))
					) : (
						<></>
					)}
				</TileSection>
			</Container>
		</>
	);
}
