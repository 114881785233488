import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { loadCurrentExhibitons, loadExhibitions } from '../../core/utils/EntityLoader';
import { getWord } from '../../core/utils/Language';
import { Colors } from '../../style/Variables';
import CommonWords from '../../types/CommonWords';
import AppearByFade from '../AnimationContainers/AppearByFade';
import DetailedThumbnailLink from '../DetailedThumbnailLink';
import EntityList from '../EntityList';
import SectionHeader from '../SectionHeader';
import { FilterProps } from '../SiteNavigation/FilterSelection';
import NavigationMenu, { NavProps } from '../SiteNavigation/NavigationMenu';

const Container = styled.div`
	width: 100%;
	margin-top: 50px;
`;

const Spacer = styled.div`
	margin-top: 116px;
	box-sizing: border-box;

	width: calc(100% + 2 * var(--gridMargin));
	margin-left: calc(-1 * var(--gridMargin));
`;

const ListWrapper = styled(Spacer)`
	margin-left: calc(-1 * var(--gridMargin));
`;

const FlexWrapper = styled.div`
	margin-top: 100px;
`;

const AnimationMask = styled.div`
	width: calc(100% + 2 * var(--gridMargin));
	margin-left: calc(-1 * var(--gridMargin));
	padding: 0 var(--gridMargin);

	overflow-x: hidden;

	display: flex;
	flex-direction: column;
	gap: 144px;
`;

export default function ExhibitionList({
	infoText,
	menuProps,
	selectedFilterProps,
}: {
	infoText?: string;
	menuProps: NavProps;
	selectedFilterProps: FilterProps;
}) {
	const [currentExhibitions, setCurrentExhibitions] = useState([]);
	const [upcomingExhibitons, setUpcomingExhibitions] = useState([]);

	useEffect(() => {
		loadCurrent();
		loadUpcoming();
	}, []);

	useEffect(() => {
		loadCurrent();
		loadUpcoming();
	}, [selectedFilterProps]);

	async function loadCurrent() {
		var json = await loadCurrentExhibitons(selectedFilterProps.selectedFilters);
		if (json.data?.results) {
			setCurrentExhibitions(json.data?.results);
		}
	}

	async function loadUpcoming() {
		var json = await loadExhibitions(10, selectedFilterProps.selectedDateFilter, selectedFilterProps.selectedFilters);
		if (json.data?.results) {
			setUpcomingExhibitions(json.data?.results);
		}
	}

	return (
		<>
			<NavigationMenu
				breadcrumb={menuProps.breadcrumb}
				type={menuProps.type}
				startMenuColor={Colors.black}
				filterProps={{
					filterArray: selectedFilterProps.filterArray,
					selectedFilters: selectedFilterProps.selectedFilters,
					selectedDateFilter: selectedFilterProps.selectedDateFilter,
					changeHandler: selectedFilterProps.changeHandler,
					dateChangeHandler: selectedFilterProps.dateChangeHandler,
				}}
			/>
			<Container>
				<Spacer />
				<SectionHeader
					title={getWord(CommonWords.current)}
					subtitle={infoText}
					numberOfItems={currentExhibitions.length}
				/>
				<FlexWrapper>
					<AnimationMask>
						{currentExhibitions.map((exhibitions, i) => (
							<AppearByFade key={i}>
								<DetailedThumbnailLink key={i} data={exhibitions}></DetailedThumbnailLink>
							</AppearByFade>
						))}
					</AnimationMask>
				</FlexWrapper>
				<Spacer style={{ borderTop: '1px solid ' + Colors.black, paddingTop: '100px' }} />
				<SectionHeader title={getWord(CommonWords.upcoming)} numberOfItems={upcomingExhibitons.length} />
				<FlexWrapper>
					<AnimationMask>
						{upcomingExhibitons.map((exhibitions, i) => (
							<AppearByFade key={i}>
								<DetailedThumbnailLink key={i} data={exhibitions}></DetailedThumbnailLink>
							</AppearByFade>
						))}
					</AnimationMask>
				</FlexWrapper>
				<ListWrapper>
					<EntityList
						data={{
							numberOfEntitiesShown: 4,
							selectedEntities: 'exhibitions',
							archivedEntities: true,
							title: getWord(CommonWords.archived),
						}}
					></EntityList>
				</ListWrapper>
			</Container>
		</>
	);
}
