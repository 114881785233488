import React from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import { Breakpoints, Fonts, Colors, Index } from '../../style/Variables';
import LeftArrow from '../../assets/svgs/left-arrow.svg';
import RightArrow from '../../assets/svgs/right-arrow.svg';
import Link from '../../core/PageSwitch/Link';
import SimpleButton from '../Buttons/SimpleButton';

const ImageFooter = styled.div`
	position: absolute;
	bottom: 50px;
	left: 0;
	width: 100%;
	height: auto;
	padding: 0 var(--gridMargin);
	z-index: 9;
	${Functions.breakpoint(Breakpoints.mobile)} {
		bottom: 80px;
	}
`;

const ImageFooterContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-direction: column;
	gap: 40px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		flex-direction: row;
	}
`;

const FooterTextContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

const FooterHeadingLink = styled(Link)`
	margin: 0;
	margin-left: -0.1em;
	margin-bottom: -0.21em;
	font-family: ${Fonts.sprittenFamily};
	text-transform: uppercase;
	font-weight: 700;
	font-size: var(--fontHuge);
	width: 100%;
	color: ${Colors.white};
	${Functions.breakpoint(Breakpoints.laptop)} {
		width: 70%;
	}
`;

const FooterHeading = styled.div`
	margin: 0;
	margin-left: -0.1em;
	margin-bottom: -0.21em;
	font-family: ${Fonts.sprittenFamily};
	text-transform: uppercase;
	font-weight: 700;
	font-size: var(--fontHuge);
	width: 100%;
	color: ${Colors.white};
	${Functions.breakpoint(Breakpoints.laptop)} {
		width: 70%;
	}
`;

const FooterLabel = styled.p`
	margin: 0;
	font-family: ${Fonts.monoFamilyLight};
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	color: ${Colors.white};
	letter-spacing: 0.1em;
	line-height: 16.8px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 16px;
		line-height: 19.2px;
	}
`;

const FooterActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 32px;
`;

const Counter = styled.div`
	font-family: ${Fonts.monoFamilyLight};
	font-weight: 400;
	display: flex;
	align-items: center;
	gap: 10px;
	color: ${Colors.white};
	span {
		font-family: 14px;
		${Functions.breakpoint(Breakpoints.mobile)} {
			font-size: 16px;
		}
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	padding: 14px;
	border: 1px solid ${Colors.white};
`;

const Button = styled(SimpleButton)`
	width: 20px;
	height: 11px;
	:hover,
	&:focus {
		fill: ${Colors.yellow};
		color: ${Colors.yellow};
		outline: none;
		svg {
			fill: ${Colors.yellow};
			color: ${Colors.yellow};
		}
	}
`;

interface HeroFooterProps {
	activeContent: { link?: { slug: string }; eyebrow: string; header: string };
	active: number;
	total: number;
	next: () => void;
	previous: () => void;
}

const HeroFooter = (props: HeroFooterProps) => {
	const { activeContent, active, total, next, previous } = props;

	return (
		<ImageFooter>
			<ImageFooterContent>
				<FooterTextContainer>
					<FooterLabel>{activeContent.eyebrow}</FooterLabel>
					{activeContent?.link?.slug ? (
						<FooterHeadingLink href={activeContent.link.slug}>{activeContent.header}</FooterHeadingLink>
					) : (
						<FooterHeading>{activeContent.header}</FooterHeading>
					)}
				</FooterTextContainer>
				{total > 1 && (
					<FooterActions>
						<Counter>
							<span>{active}</span>
							<span>/</span>
							<span>{total}</span>
						</Counter>
						<ButtonGroup>
							<Button onClick={previous}>
								<LeftArrow />
							</Button>
							<Button onClick={next}>
								<RightArrow />
							</Button>
						</ButtonGroup>
					</FooterActions>
				)}
			</ImageFooterContent>
		</ImageFooter>
	);
};

export default HeroFooter;
