import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import MagicModuleSwitcher from '../modules/MagicModuleSwitcher';
import ReadNext from '../modules/ReadNext';
import TitleHero from '../modules/TitleHero';
import { Page, PageContent } from '../style/Shared';
import { Breakpoints, Colors, Fonts, Index } from '../style/Variables';
import NavigationMenu from '../modules/SiteNavigation/NavigationMenu';
import Functions from '../style/Functions';
import { getWord } from '../core/utils/Language';
import CommonWords from '../types/CommonWords';

const ContentWrapper = styled(PageContent)``;

const Wrapper = styled.div`
	box-sizing: border-box;
`;

const StickyWrapper = styled.div`
	position: sticky;
	top: 400px;
`;

const HeroWrapper = styled.div`
	position: relative;
	z-index: ${Index.filter};
	-webkit-transform: translate3d(0, 0, 0);
`;

const AuthorWrapper = styled.div`
	background-color: ${Colors.black};
	color: ${Colors.yellow};

	width: fit-content;
	text-align: center;
	/* margin: 0 2px; */
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 2px;
	padding-right: 2px;
	line-height: 140%;
	font-weight: 300;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-left: ${Functions.col(2, 12, 1)};
	}
`;

const Container = styled.div`
	width: 100%;
	margin-top: var(--contentModuleGap);
	display: flex;
	justify-content: center;
	${Functions.breakpoint(Breakpoints.mobile)} {
		justify-content: start;
	}
`;

const Text = styled.p`
	margin: 0;
	line-height: 140%;
	font-weight: 300;
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontDefaultSize);
`;

export default function ArticlePage(props: { content?: any }) {
	const stickyRef = useRef<HTMLDivElement>(undefined);
	const wrapperRef = useRef<HTMLDivElement>(undefined);

	useEffect(() => {
		onResize();

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const onResize = () => {
		let newHeight = stickyRef.current.offsetHeight;
		// 100 is the margin that makes it fit
		wrapperRef.current.style.height = window.innerHeight + newHeight + 400 + 'px';
	};
	return (
		<Page>
			<ContentWrapper id='contentWrapper'>
				<NavigationMenu breadcrumb={props.content.title} type={props.content.modelId} startMenuColor={Colors.black} />
				<Wrapper ref={wrapperRef}>
					<HeroWrapper>
						<div style={{ position: 'absolute', top: 0, left: 0 }}>
							<TitleHero
								props={{
									title: props.content.title,
									subtitle: props.content.subtitle,
									image: props.content.image,
									fullBleedImage: false,
									date: { startDate: props.content.createdAt },
									isDarkTheme: props.content.heroTextTheme,
								}}
							/>
						</div>
					</HeroWrapper>

					<StickyWrapper ref={stickyRef}>
						<MagicModuleSwitcher modules={props.content ? props.content.modules : []} />
						{props.content.author && (
							<Container>
								<AuthorWrapper>
									<Text style={{ textTransform: 'capitalize' }}>{getWord(CommonWords.writtenBy)}</Text>
									<Text>{props.content.author}</Text>
								</AuthorWrapper>
							</Container>
						)}
					</StickyWrapper>
				</Wrapper>

				<ReadNext type='news' entityDate={props.content.createdAt} />
			</ContentWrapper>
		</Page>
	);
}
