import React from 'react';
import styled from 'styled-components';
import { getWord } from '../../core/utils/Language';
import CommonWords from '../../types/CommonWords';
import EntityList from '../EntityList';
import { FilterProps } from '../SiteNavigation/FilterSelection';
import NavigationMenu, { NavProps } from '../SiteNavigation/NavigationMenu';

const Container = styled.div`
	width: calc(100% + 2 * var(--gridMargin));
	margin-left: calc(-1 * var(--gridMargin));
	margin-right: calc(-1 * var(--gridMargin));
	margin-top: 100px;
`;

export default function ArticleList({
	menuProps,
	selectedFilterProps,
}: {
	menuProps: NavProps;
	selectedFilterProps: FilterProps;
}) {
	return (
		<>
			<NavigationMenu
				breadcrumb={menuProps.breadcrumb}
				type={menuProps.type}
				startMenuColor={Colors.black}
				filterProps={{
					filterArray: selectedFilterProps.filterArray,
					selectedFilters: selectedFilterProps.selectedFilters,
					changeHandler: selectedFilterProps.changeHandler,
				}}
			/>
			<Container>
				<EntityList
					data={{
						archivedEntities: false,
						numberOfEntitiesShown: 10,
						selectedEntities: 'news',
						title: getWord(CommonWords.news),
						shouldShowAmounts: true,
						selectedFilterProps: selectedFilterProps,
					}}
				></EntityList>
			</Container>
		</>
	);
}
