import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Link from '../../core/PageSwitch/Link';
import HTML from '../../core/utils/HTML';
import { getWord } from '../../core/utils/Language';
import Functions from '../../style/Functions';
import { Breakpoints, Fonts, Colors } from '../../style/Variables';
import CommonWords from '../../types/CommonWords';
import LinkButton from '../Buttons/LinkButton';
import RichTextModule from '../RichTextModule';
import SectionHeader from '../SectionHeader';
import StyledButton from '../Buttons/StyledButton';
import StyledButtonBig from '../Buttons/StyledButtonBig';
import TicketInfo from '../TicketInfo';
import CustomOverDetection from '../../core/utils/CustomOverDetection';
import CustomEvents, { Events } from '../../core/CustomEvents';

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	padding-top: var(--textModuleGap);
`;

const SplitSection = styled.div`
	width: 100%;
	display: flex;
	gap: var(--gridGutter);
	flex-direction: column-reverse;

	${Functions.breakpoint(Breakpoints.mobile)} {
		flex-direction: row;
	}
`;

const FirstSplitSection = styled(SplitSection)`
	margin-top: 70px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		// margin-top: 257px;
	}
`;

const SecondSplitSection = styled(SplitSection)`
	margin-top: 70px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 84px;
	}
`;

const Section = styled.div`
	width: 100%;
	box-sizing: border-box;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-left: ${Functions.col(1, 12, 1)};
	}
`;

const SectionSpecial = styled(Section)`
	margin-left: 0;
`;

const MarginWrapper = styled.div`
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-left: ${Functions.col(1, 6, 1)};
	}
`;

const TicketContainer = styled.div`
	margin-top: -8px;
	width: fit-content;

	${Functions.breakpoint(Breakpoints.ultra)} {
		margin-top: -16px;
	}
`;

const ButtonWrapper = styled.div`
	margin-top: 84px;
	width: fit-content;
`;

const FacebookButton = styled.button`
	/* transition: background-color 0.1s linear 0s; */

	${Functions.slideInHover(Colors.yellow)};

	&:focus,
	:hover {
		/* background-color: ${Colors.yellow}; */
		color: ${Colors.black};
		cursor: none;
	}
`;

const CommonText = styled.div`
	font-family: ${Fonts.monoFamily};
	font-size: var(--fontSmall);
	line-height: 160%;
	letter-spacing: 0.04em;

	text-transform: capitalize;
`;

const ShareLinksContainer = styled(CommonText)`
	display: flex;
	gap: 20px;
`;

const ShareLabel = styled(CommonText)`
	font-weight: 600;
`;

const SocialLink = styled(Link)`
	${Functions.slideInHover(Colors.yellow)};

	&:focus,
	:hover {
		color: ${Colors.black};
		cursor: none;
	}
`;

export interface EventInfoProps {
	quote: string;
	description: string;
	price: string;
	ticketLinkText: string;
	ticketLink: string;
	pressReleaseLinkText: string;
	pressReleaseLink: string;
	shareLinks: any[];
	location: { location: string; openingHours: string };
}

export default function EventInfoSection(props: EventInfoProps) {
	const FacebookShareButtonRef = useRef();

	const [hasMounted, setHasMounted] = useState(false);

	useEffect(() => {
		setHasMounted(true);
	}, []);

	useEffect(() => {
		CustomOverDetection.Instance.add(FacebookShareButtonRef.current, mouseOver, mouseOut, () => {}, 1);
		return () => {
			CustomOverDetection.Instance.remove(FacebookShareButtonRef.current);
		};
	}, []);

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	return (
		<Container>
			<SectionHeader subtitle={props.quote} />
			<FirstSplitSection>
				<Section>
					<RichTextModule text={props.description} />
					{props.pressReleaseLink && (
						<ButtonWrapper>
							<LinkButton to={props.pressReleaseLink}>
								<StyledButton
									text={props.pressReleaseLinkText ? props.pressReleaseLinkText : getWord(CommonWords.readMore)}
								/>
							</LinkButton>
						</ButtonWrapper>
					)}
				</Section>
				<Section>
					{props.ticketLink && (
						<TicketContainer>
							<LinkButton to={props.ticketLink}>
								<StyledButtonBig
									text={(props.ticketLinkText ? props.ticketLinkText : getWord(CommonWords.tickets)) + ' ↗'}
								/>
							</LinkButton>
						</TicketContainer>
					)}
				</Section>
			</FirstSplitSection>
			<SecondSplitSection>
				<SectionSpecial>
					<MarginWrapper>
						<>
							<ShareLabel>{getWord(CommonWords.share)}</ShareLabel>
							<ShareLinksContainer>
								{hasMounted && (
									<SocialLink
										to={
											'https://twitter.com/intent/tweet?text=' +
											encodeURI(getWord(CommonWords.shareMessage)) +
											' ' +
											location.href +
											'.'
										}
									>
										<span>Twitter</span>
									</SocialLink>
								)}
								{hasMounted && (
									<FacebookButton
										ref={FacebookShareButtonRef}
										onClick={() => {
											window.open(
												'https://www.facebook.com/sharer/sharer.php?u=' +
													location.href +
													'&quote=' +
													encodeURI(getWord(CommonWords.shareMessage)),
												'Share on facebook',
												'width=550,height=600'
											);
										}}
									>
										<span>Facebook</span>
									</FacebookButton>
								)}
							</ShareLinksContainer>
						</>
					</MarginWrapper>
				</SectionSpecial>
				<SectionSpecial>
					<TicketInfo
						location={props.location?.location}
						openingHours={props.location?.openingHours}
						price={props.price}
					></TicketInfo>
				</SectionSpecial>
			</SecondSplitSection>
		</Container>
	);
}
