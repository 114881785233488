import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Fonts, Colors, Index, Breakpoints } from '../../style/Variables';
import gsap, { Power1 } from 'gsap';
import Link from '../../core/PageSwitch/Link';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import CustomEvents, { Events } from '../../core/CustomEvents';
import FullScreenMenu from './FullScreenMenu';
import FilterSelection, { FilterProps } from './FilterSelection';
import CustomOverDetection from '../../core/utils/CustomOverDetection';
import CollapsiblePlus from '../CollapsiblePlus';
import Functions from '../../style/Functions';
import { getWord } from '../../core/utils/Language';
import CommonWords from '../../types/CommonWords';
import LogoViewer from '../LogoViewer';

const FullScreenHelper = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	z-index: -1;
`;

const OpenMenu = styled.div`
	grid-column: 12;
	justify-self: end;
	&:focus,
	:hover {
		transition:
			background-color 0.4s ease,
			0.5s;
		background-color: ${Colors.yellow};
	}

	/* transition: background-color 0.1s linear 0s; */
	${Functions.breakpoint(Breakpoints.mobile)} {
		${Functions.slideInHover(Colors.yellow)}
		margin-right: 0.6em;

		&:focus,
		:hover {
			/* background-color: ${Colors.yellow}; */
			color: ${Colors.black};
		}
	}
`;

const LogoContainer = styled.div<{ short: boolean }>`
	grid-column: ${props => (props.short ? 'span 7' : 'span 9')};
	display: flex;

	white-space: pre;
	${Functions.breakpoint(Breakpoints.mobile)} {
		white-space: initial;
		align-items: center;
		white-space: nowrap;
	}
`;

const LogoLink = styled(Link)`
	display: flex;

	${Functions.breakpoint(Breakpoints.mobile)} {
		white-space: initial;
		align-items: center;
		white-space: nowrap;

		&:focus,
		:hover {
			/* background-color: ${Colors.yellow}; */
			color: ${Colors.black};
		}
	}

	transition: background-color 0.1s linear 0s;
`;

const ScrolledMenu = styled.div`
	position: fixed;
	z-index: ${Index.scrolledNavMenu};
	width: 100vw;
	left: 0px;
	background: ${Colors.white};
	border-bottom: 1px solid ${Colors.black};
`;

const ElementLinkContainer = styled.div`
	white-space: pre;
`;

const MenuItemContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--gridGutter);
	padding: 0 var(--gridMargin);

	opacity: 0;

	width: 100%;
	height: 60px;
	/* display: flex; */
	background-color: white;
	align-items: center;
	box-sizing: border-box;

	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontNotQuiteMedium);
	${Functions.breakpoint(Breakpoints.mobile)} {
		height: 80px;
	}
`;

const FilterButton = styled.div`
	display: flex;
	align-items: center;
`;

const MidItemContainer = styled.div`
	grid-column: 8;
	display: flex;
`;

const TicketPlacer = styled.div`
	grid-column: 10 / span 2;
	display: flex;
`;

const FilterSelectionPlacer = styled.div`
	overflow: hidden;
	opacity: 0;
	max-height: 0;
	background-color: white;
	border-bottom: 1px solid ${Colors.black};
	text-overflow: ellipsis;
	white-space: nowrap;

	transform: scale(0);
`;

const BreadcrumbContainer = styled.span`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: inline-block;
		white-space: nowrap;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const StyledLink = styled(Link)`
	transition: background-color 0.1s linear 0s;

	&:focus,
	:hover {
		background-color: ${Colors.yellow};
		color: ${Colors.black};
	}
`;

const BottomContainer = styled.div`
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontNotQuiteMedium);
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: ${Index.navMenu};

	background-color: ${Colors.white};

	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	height: 50px;

	border-top: 1px solid ${Colors.black};

	padding-right: var(--gridMargin);
`;

const SpanContainer = styled.span`
	margin: 15px 0px;
	transition: background-color 0.4s ease 0s;
	:hover {
		background-color: ${Colors.yellow};
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin: 0;
		font-size: var(--fontNotQuiteMedium);
	}
`;

export interface NavProps {
	breadcrumb?: string;
	type?: string;
	dates?: any;
	ticketLinkText?: string;
	ticket?: string;
	startMenuColor?: string;
	filterProps?: FilterProps;
}

export const revealKey = 'didReveal';
export const revealValue = 'true';

export function setDidRevealStorage() {
	sessionStorage.setItem(revealKey, revealValue);
}

export default function NavigationMenu(props: NavProps) {
	const [menuOpened, setMenuOpened] = useState(false);
	const ScrolledMenuRef = useRef<HTMLDivElement>();
	const FullScreenHelperRef = useRef<HTMLDivElement>();
	const TitleContainerRef = useRef<HTMLDivElement>();
	const DateContainerRef = useRef<HTMLDivElement>();
	const TicketLinkRef = useRef<HTMLDivElement>();
	const mobileTicketLinkRef = useRef<HTMLDivElement>();
	const OpenMenuRef = useRef<HTMLDivElement>();
	const NavigationMenuRef = useRef<HTMLDivElement>();
	const mobileFilterContainerRef = useRef<HTMLDivElement>();

	const filterLogoRef = useRef<HTMLDivElement>(undefined);
	const filterPlacerRef = useRef<HTMLDivElement>(undefined);
	const [filterOpen, setFilterOpen] = useState<boolean>(false);
	const didMountRef = useRef(false);

	const [isMobile, setIsMobile] = useState(false);

	const onBreakpointChange = () => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		onBreakpointChange();
		CustomOverDetection.Instance.add(OpenMenuRef.current, mouseOver, mouseOut, () => {}, Index.scrolledNavMenu);
		CustomOverDetection.Instance.add(filterLogoRef.current, mouseOver, mouseOut, () => {}, Index.scrolledNavMenu);
		CustomOverDetection.Instance.add(TicketLinkRef.current, mouseOver, mouseOut, () => {}, Index.scrolledNavMenu);

		CustomOverDetection.Instance.add(NavigationMenuRef.current, mouseOverStandard, mouseOut, () => {}, Index.navMenu);
		CustomEvents.listen(Events.RESIZE, onBreakpointChange);

		window.addEventListener('keydown', handleEscape);

		//CustomEvents.listen(Events.RESIZE, test);
		return () => {
			CustomEvents.remove(Events.RESIZE, onBreakpointChange);
			CustomOverDetection.Instance.remove(NavigationMenuRef.current);
			CustomOverDetection.Instance.remove(OpenMenuRef.current);
			CustomOverDetection.Instance.remove(filterLogoRef.current);
			CustomOverDetection.Instance.remove(TicketLinkRef.current);

			window.removeEventListener('keydown', handleEscape);
		};
	}, []);

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOverStandard = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		var tween;
		if (props.breadcrumb === 'Frontpage') {
			gsap.to([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
				opacity: 1,
				y: 0,
			});

			// if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			// 	if (sessionStorage.getItem(revealKey) === revealValue) {
			// 		gsap.set([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
			// 			y: 0,
			// 			opacity: 1,
			// 			color: 'black',
			// 			borderBottomWidth: 1,
			// 			borderBottomColor: 'black',
			// 			display: 'grid',
			// 		});
			// 	} else {
			// 		gsap.set([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
			// 			opacity: 1,
			// 			y: -50,
			// 			display: 'grid',
			// 			color: 'white',
			// 			borderBottomWidth: 0,
			// 			borderBottomColor: 'white',
			// 		});
			// 		gsap.to([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
			// 			y: 0,
			// 			color: 'black',
			// 			borderBottomWidth: 1,
			// 			borderBottomColor: 'black',
			// 			display: 'grid',
			// 			duration: 0.5,
			// 			delay: 2,
			// 		});
			// 	}
			// } else {
			// 	gsap.to([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
			// 		opacity: 0,
			// 		y: -50,
			// 		duration: 0,
			// 		display: 'none',
			// 	});
			// }
			// tween = gsap
			// 	.timeline({
			// 		scrollTrigger: {
			// 			trigger: FullScreenHelperRef.current,
			// 			start: '100%-=45px',
			// 			end: '5000%',
			// 			scrub: false,
			// 			toggleActions: 'play complete reverse reverse',
			// 		},
			// 	})
			// 	.to([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
			// 		opacity: 1,
			// 		display: 'grid',
			// 		y: 0,
			// 		duration: 0.2,
			// 	});
		} else if (props.type === 'listPage') {
			gsap.to([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
				opacity: 1,
				y: 0,
			});
		} else if (props.type === 'contentPage') {
			gsap.set([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
				opacity: 1,
				y: 0,
				backgroundColor: isMobile ? Colors.white : Colors.white,
				borderBottomColor: isMobile ? Colors.black : Colors.black,
			});
			gsap.to(TitleContainerRef.current, {
				y: -50,
				opacity: 0,
			});

			tween = gsap
				.timeline({
					scrollTrigger: {
						trigger: FullScreenHelperRef.current,
						start: '100%-=45px',
						end: '500%',
						scrub: false,
						toggleActions: 'play complete reverse reverse',
					},
				})
				.to(TitleContainerRef.current, {
					duration: 0.1,
					y: 0,
					opacity: 1,
				});
		} else if (props.type === 'errorPage') {
			gsap.set([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
				opacity: 1,
				y: 0,
				backgroundColor: isMobile ? Colors.white : 'transparent',
				borderBottomColor: isMobile ? Colors.black : 'transparent',
			});
			gsap.to(TitleContainerRef.current, {
				y: -50,
				opacity: 0,
			});

			tween = gsap
				.timeline({
					scrollTrigger: {
						trigger: FullScreenHelperRef.current,
						start: '100%-=45px',
						end: '500%',
						scrub: false,
						toggleActions: 'play complete reverse reverse',
					},
				})
				.to(TitleContainerRef.current, {
					duration: 0.1,
					y: 0,
					opacity: 1,
				});
		} else {
			gsap.to([ScrolledMenuRef.current, mobileFilterContainerRef.current], {
				opacity: 1,
				y: 0,
				duration: 0,
				borderBottomWidth: 1,
				backgroundColor: isMobile ? Colors.white : 'transparent',
				borderBottomColor: isMobile ? Colors.black : 'transparent',
				color: props.startMenuColor ? props.startMenuColor : isMobile ? Colors.black : Colors.white,
			});

			gsap.to([TitleContainerRef.current, DateContainerRef.current, TicketLinkRef.current], {
				y: -50,
				opacity: 0,
			});

			gsap.to(mobileTicketLinkRef.current, {
				y: 50,
				opacity: 0,
			});

			tween = gsap
				.timeline({
					scrollTrigger: {
						trigger: FullScreenHelperRef.current,
						start: '100%-=45px',
						end: '500%',
						scrub: false,
						toggleActions: 'play complete reverse reverse',
					},
				})
				.to(
					[ScrolledMenuRef.current, mobileFilterContainerRef.current],
					{
						backgroundColor: Colors.white,
						borderBottomColor: isMobile ? Colors.black : Colors.black,
						duration: 0.2,
						color: Colors.black,
					},
					'label'
				)
				.to(
					TitleContainerRef.current,
					{
						duration: 0.2,
						y: 0,
						opacity: 1,
					},
					'label'
				)
				.to(
					DateContainerRef.current,
					{
						duration: 0.2,
						y: 0,
						opacity: 1,
					},
					'label'
				)
				.to(
					TicketLinkRef.current,
					{
						duration: 0.2,
						y: 0,
						opacity: 1,
					},
					'label'
				)
				.to(
					mobileTicketLinkRef.current,
					{
						duration: 0.2,
						y: 0,
						opacity: 1,
					},
					'<'
				);
		}
		const adjust = () => {
			try {
				// @ts-ignore
				if (tween) {
					tween.scrollTrigger.refresh();
				}
			} catch (e) {
				console.log('we got issues', e);
				//Bugsnag.notify(`Failed to refresh tweens ScrollTrigger : ` + e);
			}
		};

		CustomEvents.listen(Events.TRANSITIONIN, adjust);
		return function () {
			if (tween) tween.kill();

			tween = null;
			CustomEvents.remove(Events.TRANSITIONIN, adjust);
			document.documentElement.style.removeProperty('overflow');
		};
	}, [isMobile]);

	useEffect(() => {
		if (didMountRef.current) {
			if (filterOpen) {
				openFilter();
			} else {
				closeFilter();
			}
		}

		didMountRef.current = true;
	}, [filterOpen]);

	const fixedDate = dates => {
		let RSD = new Date(dates.startDate);
		let RED = dates.endDate ? new Date(dates.endDate) : undefined;

		return (
			RSD.getDate() +
			'.' +
			Number(RSD.getMonth() + 1) +
			(RED ? '-' + RED.getDate() + '.' + Number(RED.getMonth() + 1) : '') +
			' ' +
			(RED ? RED.getFullYear() : RSD.getFullYear())
		);
	};

	const openMenu = () => {
		setMenuOpened(true);
	};

	const toggleMenu = value => {
		setMenuOpened(value);
	};

	const toggleFilterOpen = () => {
		setFilterOpen(!filterOpen);
	};

	const openFilter = () => {
		let tl = gsap.timeline();

		if (filterPlacerRef) {
			tl.set(filterPlacerRef.current, {
				scale: 1,
			});
			tl.to(filterPlacerRef.current, {
				overwrite: 'auto',
				duration: 0.2 + 's',
				autoAlpha: 1,
			});
			tl.to(filterPlacerRef.current, {
				ease: Power1.easeIn,
				duration: 0.2,
				maxHeight: filterPlacerRef.current.children[0].clientHeight,
			});
			tl.set(filterPlacerRef.current, {
				overflow: 'visible',
			});
		}
	};

	const closeFilter = () => {
		let tl = gsap.timeline();

		tl.to(filterPlacerRef.current, {
			overflow: 'hidden',

			overwrite: true,
			maxHeight: '0px',
			duration: 0.2,
			ease: Power1.easeIn,
		});
		tl.to(filterPlacerRef.current, {
			duration: 0.2 + 's',
			autoAlpha: 0,
		});
		tl.set(filterPlacerRef.current, {
			scale: 0,
		});
	};
	const handleEscape = event => {
		if (event.key === 'Escape') {
			setFilterOpen(false);
		}
	};

	return (
		<>
			<FullScreenHelper ref={FullScreenHelperRef} />
			{/* {props.breadcrumb === 'Frontpage' && !isMobile && <FrontpageNavigation fullPage={FullScreenHelperRef} />} */}
			<ScrolledMenu ref={NavigationMenuRef}>
				<MenuItemContainer ref={ScrolledMenuRef}>
					<LogoContainer short={props.type === 'listPage'}>
						{/* <MalteseCross cross={true} isSolid text='' /> */}
						<LogoLink to='/' customZIndex={Index.scrolledNavMenu}>
							<LogoViewer />
						</LogoLink>
						{props.breadcrumb && props.breadcrumb !== 'Frontpage' && (
							<BreadcrumbContainer title={props.breadcrumb} ref={TitleContainerRef}>
								&nbsp;/ {props.breadcrumb}{' '}
							</BreadcrumbContainer>
						)}
						{props.dates && (
							<BreadcrumbContainer title={fixedDate(props.dates)} ref={DateContainerRef}>
								&nbsp;/ {fixedDate(props.dates)}
							</BreadcrumbContainer>
						)}
					</LogoContainer>
					{props.ticket && !isMobile && (
						<TicketPlacer>
							<ElementLinkContainer ref={TicketLinkRef}>
								<StyledLink to={props.ticket}>
									{(props.ticketLinkText ? props.ticketLinkText : getWord(CommonWords.tickets)) + '↗'}
								</StyledLink>
							</ElementLinkContainer>
						</TicketPlacer>
					)}
					{props.type === 'listPage' && !isMobile && (
						<MidItemContainer>
							<FilterButton ref={filterLogoRef} onClick={toggleFilterOpen}>
								<CollapsiblePlus isOpen={filterOpen}></CollapsiblePlus>{' '}
								<span style={{ marginLeft: '1em' }}>FILTER</span>
							</FilterButton>
						</MidItemContainer>
					)}
					<OpenMenu ref={OpenMenuRef} onClick={openMenu}>
						<SpanContainer>Menu</SpanContainer>
					</OpenMenu>
				</MenuItemContainer>
				{isMobile && props.type === 'listPage' && (
					<MenuItemContainer ref={mobileFilterContainerRef}>
						<FilterButton ref={filterLogoRef} onClick={toggleFilterOpen}>
							<CollapsiblePlus isOpen={filterOpen}></CollapsiblePlus> <span style={{ marginLeft: '1em' }}>FILTER</span>
						</FilterButton>
					</MenuItemContainer>
				)}
				{props.type === 'listPage' && (
					<FilterSelectionPlacer ref={filterPlacerRef}>
						<FilterSelection
							filterArray={props.filterProps.filterArray}
							changeHandler={props.filterProps.changeHandler}
							selectedFilters={props.filterProps.selectedFilters}
							selectedDateFilter={props.filterProps.selectedDateFilter}
							dateChangeHandler={props.filterProps.dateChangeHandler}
						></FilterSelection>
					</FilterSelectionPlacer>
				)}
			</ScrolledMenu>
			{isMobile && props.ticket && (
				<BottomContainer ref={mobileTicketLinkRef}>
					<ElementLinkContainer>
						<StyledLink to={props.ticket}>
							{(props.ticketLinkText ? props.ticketLinkText : getWord(CommonWords.tickets)) + '↗'}
						</StyledLink>
					</ElementLinkContainer>
				</BottomContainer>
			)}
			<FullScreenMenu updateParentToggle={toggleMenu} toggleMenu={menuOpened} />
		</>
	);
}
